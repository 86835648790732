<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark"> Collection Receive</h1>
                    </div><!-- /.col --> 
                </div><!-- /.row --> 
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rider_id">Rider</label>
                                            <v-select name="reason_id"
                                                v-model="search.rider_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= riderList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                     <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="rider_id">Merchant</label>
                                            <v-select name="merchant_id"
                                                v-model="search.merchant_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= merchantList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12" v-if="authUser.role_id != 7">
                                        <div class="form-group">
                                            <label for="title">{{ $t('globalTrans.hub') }} <span class="text-danger" title="Required">*</span></label>
                                            <v-select name="hub_id"
                                                v-model="search.hub_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= hubList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="activity_date">Activity Date</label>
                                            <input type="date" id="activity_date" v-model="search.activity_date" class="form-control" />
                                        </div> 
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="status">Status</label>
                                            <v-select name="status"
                                                multiple
                                                v-model="search.status"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= statusList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                     <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group" style="margin-top:28px;">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData"><i class="fa fa-search"></i></button>
                                            <button class="btn btn-warning btn-sm ml-2" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                             <a class="btn btn-success btn-sm ml-2" target="_blank" :href="$baseUrl + 'excel-download/collection-receive?hub_id='+search.hub_id">
                                                <i class="fa fa-download"></i> Excel
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table id="collectionTable" class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <td colspan="3">
                                                        <label>
                                                            <input type="checkbox" @click="selectAll" v-model="allSelected">
                                                            <p>Select All</p>
                                                        </label>
                                                    </td>
                                                    <td colspan="2">
                                                        <label><p>Total Order : {{ this.totalOrder }}</p></label>
                                                    </td>
                                                    <td colspan="3">
                                                        <label><p>Total Amount : {{ this.totalAmount }}</p></label>
                                                        <button class="btn btn-success float-right btn-sm mr-2" @click="receive"> Receive</button>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Merchant</th>
                                                    <th>Rider</th>
                                                    <th>Destination Hub</th>
                                                    <th>Date</th>
                                                    <th>Price</th>
                                                    <th>Collected</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Merchant</th>
                                                    <th>Rider</th>
                                                    <th>Destination Hub</th>
                                                    <th>Date</th>
                                                    <th>Price</th>
                                                    <th>Collected</th>
                                                    <th>Status</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, index) in collections" :key="index">
                                                    <td class="order-id">
                                                        <label>
                                                            <input v-model="form.orderIds" @click="select(item.id)" :value="item.id" type="checkbox" class="order-checkbox" />
                                                            <p>{{ item.id }}</p>
                                                        </label>
                                                    </td>
                                                    <td @click="orderLog(item.logs)">{{  item.merchant ? item.merchant.business : '' }}</td>
                                                    <td>{{ item.rider ? item.rider.name : '' }}</td>
                                                    <td>{{ item.destination ? item.destination.name : '' }}</td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.price }}</td>
                                                    <td>{{ item.collected }}</td>
                                                    <td><span class="label p-1" :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                    <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                    </div>
                    <div class="modal-body">
                        <input type="hidden" name="del_id" />
                        <h3 class="text-center">Are you sure to delete ?</h3>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" @click="destroycollection">Delete</button>
                        <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.content -->
        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th>Action By</th>
                                <th>Action</th>
                                <th>Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in order_logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                            </tr>
                        </table>                      
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    export default {
        name:'Collection',
        data(){
            return {   
                orderLogModal:false,
                order_logs:[],
                loader: true,             
                loading: false,             
                collections:{
                    data: []
                },
                statusList: [
                    { text: 'Delivered', id: 14 },
                    { text: 'Partial Delivered', id: 15 },
                    { text: 'Damage/Lost', id: 16 },
                    { text: 'Exchanged', id: 19 },
                    { text: 'Cancel', id: 20 }
                ],
                search: {
                    rider_id: '',
                    merchant_id: '',
                    hub_id: '',
                    activity_date: '',
                    status: []
                },
                form:{
                    rider_id : '',
                    date : '',
                    orderIds: []
                },
                errors:[],
                deleteModal:false,
                collection_id:'',
                key:'',
                allSelected: false,
                totalAmount: 0,
                totalOrder: 0
            }
        },
        created(){
            this.loadData();
        },
        computed : {
            riderList: function () {
                if (this.authUser.role_id == 7) {
                    return this.$store.state.commonObj.riderList.filter(el => el.hub_id === this.authUser.hub_id)
                }
                return this.$store.state.commonObj.riderList
            },
            merchantList: function () {
                return this.$store.state.commonObj.merchantList
            },
            hubList () {
                return this.$store.state.commonObj.hubList
            },
            authUser () {
                return this.$store.state.authUser
            }
        },
        methods:{
            selectAll () {
                this.loader = true
                this.form.orderIds = [];
                if (!this.allSelected) {
                    this.totalOrder = this.totalAmount = 0
                    const orders = this.collections
                    for (var order in orders) {
                        this.totalAmount += parseFloat(orders[order].collected)
                        this.form.orderIds.push(orders[order].id);
                    }
                    this.totalOrder = this.form.orderIds.length
                } else {
                    this.totalOrder = 0
                    this.totalAmount = 0
                }
                this.loader = false
            },
            select(orderId) {
                const orders = this.collections
                const tmpOrder = orders.find(order => order.id == orderId)
                if (!this.form.orderIds.includes(orderId)) {
                    this.form.orderIds.push(orderId)
                    this.totalAmount += parseFloat(tmpOrder.collected)
                } else {
                    this.form.orderIds.splice(this.form.orderIds.indexOf(orderId), 1)
                    this.totalAmount -= parseFloat(tmpOrder.collected)
                } 
                this.totalOrder = this.form.orderIds.length
                this.allSelected = false;                  
            },
            async receive () {
                this.loader = true
                if (this.authUser.role_id == 7 ){
                    this.form = Object.assign(this.form, { date: this.search.date, rider_id: this.search.rider_id, hub_id: this.authUser.hub_id, collection: this.totalAmount }) 
                } else {
                    this.form  = Object.assign(this.form, { date: this.search.date, rider_id: this.search.rider_id, hub_id: this.search.hub_id, collection: this.totalAmount })
                }
                const isValidated = this.checkValidation(this.form)
                if (isValidated) {
                    const response = await config.postData('/accounts/collection/receive', this.form)
                    if (response.status == 201) {
                        this.allSelected = false
                        this.totalOrder = this.totalAmount = 0
                        this.loadData()
                        this.$toast.success({
                            title: 'Success',
                            message: 'Payment Received',
                            color: '#218838'
                        }) 
                    } else {
                        this.$toast.error({
                            title: 'Error',
                            message: 'Sorry, something went wrong',
                            color: '#dc3545'
                        })
                    }
                }
                this.loader = false
            },
            checkValidation (data) {
                if (data.orderIds.length == 0) {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Please select order',
                        color: '#dc3545'
                    }) 
                    return false
                }
                if (data.hub_id == 0) {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Please select hub',
                        color: '#dc3545'
                    }) 
                     return false
                }
                return true
            },
            searchData () {
                this.loadData()
            },
            async loadData(){     
                this.totalAmount = this.totalOrder = 0
                this.loader = true
                const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { hub_id: this.authUser.hub_id }) : this.search  
                const response = await config.getData('/accounts/collection/list', params)
                this.loader = false
                if (response.status == 200){
                    this.collections = response.data
                } else {
                    this.collections = []
                } 
            },
            reload () {
                this.search.status = []
                this.search.activity_date = this.search.rider_id = this.search.merchant_id = ''
            },
            cancelModal(){
                this.orderLogModal = false;
                this.deleteModal = false;
                document.body.classList.remove("modal-open");
            },
            destroy(key, collection_id){ 
                this.collection_id = collection_id;
                this.key = key;
                this.deleteModal = true;
            },
            async destroycollection(){
                this.loader = true
                this.$store.dispatch('stateReload', true)
                await config.deleteData('/accounts/collection/destroy/'+ this.collection_id)
                this.loader = false           
                this.deleteModal = false
                this.$toast.success({
                    title: 'Success',
                    message: 'Hub deleted successfully',
                    color: '#218838'
                }) 
            },
            getStatus (status) {
                const tmpStatus = this.$store.state.statusList.find(tmp => tmp.id == status)
                return typeof tmpStatus !== 'undefined' ? tmpStatus.text : ''
            },
            orderLog (logs) {
                this.order_logs = logs 
                this.orderLogModal = true
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
</style>